<template>
  <div class="c-header-guest justify-between p-2 mx-12">
    <router-link to="/" class="c-logo flex items-center">
      <picture>
        <source media="(min-width: 992px)" srcset="@/assets/images/logo.png" />
        <img src="@/assets/images/logo-smal.png" alt="Multicase Portal" />
      </picture>
    </router-link>

    <Button label="Login" icon="pi pi-sign-in" @click="signIn" data-testid="sign-in-button" outlined />
  </div>
</template>
<script setup lang="ts">
import { useAuth } from "@cumulus/event-bus";

const { login } = useAuth();

const signIn = async () => {
  await login();
};
</script>

<style lang="scss" scoped>
.c-navbar-guest {
  .p-button-text {
    max-width: 16rem;

    :deep(.p-button-label) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .c-header-guest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .p-button.p-button-text {
      color: #333;
    }
  }

  .c-logo {
    padding-left: 1.5rem;

    @media (max-width: 991px) {
      padding-left: 0;
    }

    img {
      height: 2.5rem;
    }
  }
}

@media (max-width: 992px) {
  .c-navbar-guest {
    .c-logo {
      padding-left: 0;
    }
    .p-button-text {
      max-width: 7rem;
    }
  }
}

.old-login {
  background: #fff;
  color: #eee;
  border: none;
}
</style>
