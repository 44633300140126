<template>
  <div></div>
</template>

<script setup lang="ts">
import { InteractionStatus, useAuth } from "@cumulus/event-bus";
import { onMounted, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { getUser, instance, inProgress, accounts } = useAuth();

const authenticate = async () => {
  if (inProgress.value === InteractionStatus.None) {
    if (accounts.value.length > 0) {
      instance.setActiveAccount(accounts.value[0]);

      await getUser();
      const fullPath = localStorage.getItem("signinRedirect");

      if (fullPath === null || fullPath === "") {
        router.push({ path: "/" });
      } else {
        router.push({ path: fullPath });
      }
      return;
    }

    window.location.href = "/";
  }
};

watch(inProgress, authenticate);
watch(accounts, authenticate);

onMounted(authenticate);
</script>
