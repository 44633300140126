{
  "common": {
    "error": "Feil",
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Ikke funnet",
      "bad-request": "Feil i forespørsel"
    },
    "countries": {
      "language": {
        "nb-NO": "Norsk",
        "en-GB": "Engelsk"
      },
      "code": {
        "47": "Norge",
        "826": "Storbritania",
        "46": "Sverige",
        "49": "Tyskland",
        "61": "Australia",
        "55": "Brasil",
        "86": "Kina",
        "20": "Egypt",
        "33": "Frankriket",
        "91": "India",
        "81": "Japan",
        "34": "Spania",
        "1": "USA"
      },
      "iso": {
        "NO": "Norge",
        "GB": "Storbritania",
        "DK": "Danmark",
        "FI": "Finland",
        "SE": "Sverige",
        "DE": "Tyskland",
        "IT": "Italia",
        "FR": "Frankriket"
      }
    }
  },

  "header": {
    "menu": "Meny",
    "search": "Søk",
    "alert-new-order": "Nye ordre",
    "alert-new-user": "Nye brukere",
    "alert-warnings": "Advarsel",
    "profile-user": "Bruker profil",
    "profile-language": "Språk",
    "profile-companies": "firmaer",
    "profile-clients": "Klienter",
    "dark-mode": "Mørk modus",
    "light-mode": "Lys modus",
    "profile-logout": "Logg ut",
    "title": {
      "order": "Order",
      "customer": "Kunde",
      "discounts": "Rabatter",
      "product": "Produkt",
      "supplier": "Leverandør",
      "purchase": "Innkjøpsordre",
      "goods-reception": "Varemottak",
      "inventory": "Beholdning",
      "inventory-count": "Lagertelling",
      "order-ready-for-picking": "Klar til plukk",
      "picking-lists": "Plukkliste",
      "packing-notes": "Pakksedler",
      "invoice": "Faktura",
      "credit-note": "Kreditnota",
      "transfer": "Overføring",
      "company-info": "Firma info",
      "settings": "Innstillinger",
      "clients": "Klient",
      "employees": "Ansatt",
      "printing": "Skrivere",
      "health": "Helsestatus",
      "companies": "Alle firmaer"
    }
  },

  "menu": {
    "dashboard": {
      "header": "Skrivebord"
    },
    "sales": {
      "header": "Salg",
      "order": "Ordre",
      "order_char": "O",
      "customer": "Kunde",
      "customer_char": "K",
      "discounts": "Rabatter",
      "discounts_char": "R"
    },
    "product": {
      "header": "Produkt",
      "product": "Produkt",
      "product_char": "P",
      "supplier": "Leverandør",
      "supplier_char": "L",
      "purchase": "Innkjøp",
      "purchase_char": "I"
    },
    "warehouse": {
      "header": "Lager",
      "goods_reception": "Varemottak",
      "goods_reception_char": "V",
      "inventory": "Beholdning",
      "inventory_char": "B",
      "inventory_count": "Lagertelling",
      "inventory_count_char": "L"
    },
    "goods_out": {
      "header": "Varer ut",
      "order_ready_for_picking": "Klar til plukk",
      "order_ready_for_picking_char": "K",
      "picking_lists": "Plukkliste",
      "picking_lists_char": "L",
      "packing_notes": "Pakksedler",
      "packing_notes_char": "P"
    },
    "finance": {
      "header": "Økonomi",
      "invoice": "Faktura",
      "invoice_char": "F",
      "credit_note": "Kreditnota",
      "credit_char": "K",
      "transfer": "Overføring",
      "transfer_char": "O",
      "settings": "Innstillinger",
      "settings_char": "I"
    },
    "administration": {
      "header": "Administrasjon",
      "company_info": "Firma info",
      "company_info_char": "F",
      "clients": "Klient",
      "clients_char": "K",
      "employees": "Ansatt",
      "employees_char": "A",
      "settings": "Innstillinger",
      "settings_char": "I",
      "printing": "Skrivere",
      "printing_char": "S"
    },
    "cumulus_tools": {
      "header": "Cumulus verktøy",
      "companies": "Alle firmaer",
      "companies_char": "A",
      "health": "Helsestatus",
      "health_char": "H"
    },
    "alerts": {
      "header": "Varsler",
      "new_order": "Ny ordre",
      "new_order_char": "",
      "new_users": "Nye brukere",
      "new_users_char": "",
      "warnings": "Advarsel",
      "warnings_char": ""
    },
    "user":{
      "header": "Bruker",
      "user_profile":"Bruker profil",
      "user_profile_char": "",
      "language": "Språk",
      "language_char": "",
      "language_norwegian":"Norsk",
      "language_norwegian_char":"",
      "language_english":"Engelsk",
      "language_english_char":"",
      "logout":"Logg ut",
      "logout_char": ""
    }
  },

  "admin-menu": {
    "header": "Hjem",
    "dashboard": "Skrivebord",
    "administration": {
      "header": "Administrasjon",
      "companies": "Bedrifter",
      "identity": {
        "header": "Identitet",
        "clients": "Enheter",
        "users": "Brukere"
      }
    },
    "health": "Helse status"
  },

  "search": {
    "all": "Alle",
    "product": "Produkt | Produkter",
    "customer": "Kunde | Kunder",
    "order": "Ordre | Ordrer",
    "manufacturer": "Produsenter | Produsenter",
    "supplier": "Leverandør | Leverandører",
    "purchase-order": "Innkjøpsordre | Innkjøpsordrer",
    "inventory": "Lagerbeholdning | Lagerbeholdninger",
    "goods-reception": "Varemottak | Varemottak",
    "customer-settings": "Kundeinnstillinger",
    "show-all": "Vis alle",
    "no-search-results": "Ingen resultater funnet for",
    "loading": "Søker..."
  }
}
