import { Company } from "@cumulus/event-bus";
import router from "@/router/router";
import { publishSetSelectedLocaleEvent } from "@cumulus/locale";
import { useI18n } from "vue-i18n";

import { companyMenu } from "./CompanyMenu";
import { clientMenu } from "./ClientMenu";
import { useAuth } from "@cumulus/event-bus";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import { ref } from "vue";

export function useProfile(themeChangedCallback: () => void) {
  const { getUser, logout } = useAuth();
  const { locale } = useI18n();
  const isMenuChanged = ref(false);

  const getMenu = async (): Promise<Array<MenuItem>> => {
    isMenuChanged.value = false;
    const user = await getUser();
    const employee = user.getEmployee();
    const activeClient = user.getClient();

    const companies = user.employees.map((employee) => {
      const company = new Company();
      company.id = employee.companyId;
      company.name = employee.companyName;
      return company;
    });

    const languageMenuItems = [] as Array<MenuItem>;
    languageMenuItems.push({
      label: "header.profile-language",
      icon: "pi pi-fw pi-flag",
      items: [
        {
          label: "common.countries.language.nb-NO",
          icon: locale.value === "nb-NO" ? "pi pi-fw pi-check" : "pi pi-fw",
          command: () => {
            publishSetSelectedLocaleEvent("nb-NO");
            isMenuChanged.value = true;
          },
        },
        {
          label: "common.countries.language.en-GB",
          icon: locale.value === "en-GB" ? "pi pi-fw pi-check" : "pi pi-fw",
          command: () => {
            publishSetSelectedLocaleEvent("en-GB");
            isMenuChanged.value = true;
          },
        },
      ],
    });

    //Only show is more then one company
    const companyMenuItems = [] as Array<MenuItem>;
    if (companies.length > 1) {
      companyMenuItems.push({
        label: "header.profile-companies",
        icon: "pi pi-fw pi-building",
        items: companyMenu(user, companies, activeClient?.companyId ?? ""),
      });
    }

    //Only show is more then one client
    const clientMenuItems = [] as Array<MenuItem>;
    if (employee.clients.length > 1) {
      clientMenuItems.push({
        label: "header.profile-clients",
        icon: "pi pi-fw pi-sitemap",
        items: clientMenu(user, employee.clients, activeClient?.id ?? ""),
      });
    }

    const hasDarkMode = localStorage.theme === "dark";
    const darkModeItems = [] as Array<MenuItem>;
    darkModeItems.push({
      label: hasDarkMode ? "header.light-mode" : "header.dark-mode",
      icon: "pi pi-fw " + (hasDarkMode ? "pi-sun" : "pi-moon"),
      command: (event: MenuItemCommandEvent) => {
        if (
          localStorage.theme === "dark" ||
          (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
          localStorage.theme = "light";
          document.documentElement.classList.add("light");
          document.documentElement.classList.remove("dark");
          event.item.label = "header.dark-mode";
          event.item.icon = "pi pi-fw pi-moon";
        } else {
          localStorage.theme = "dark";
          document.documentElement.classList.add("dark");
          document.documentElement.classList.remove("light");
          event.item.label = "header.light-mode";
          event.item.icon = "pi pi-fw pi-sun";
        }

        themeChangedCallback();
      },
    });

    return [
      {
        label: "header.profile-user",
        icon: "pi pi-fw pi-user",
        command: () => {
          router.push(`/system-data/employee/edit/${employee.id}`);
        },
      },
      ...languageMenuItems,
      ...companyMenuItems,
      ...clientMenuItems,
      ...darkModeItems,
      {
        label: "header.profile-logout",
        icon: "pi pi-fw pi-power-off",

        command: () => {
          logout();
        },
      } as MenuItem,
    ] as Array<MenuItem>;
  };

  return {
    getMenu,
    isMenuChanged,
  };
}
