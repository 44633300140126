export enum Pages {
  Default = "default",
  Product = "product",
  Customer = "customer",
  Order = "order",
  Supplier = "supplier",
  PurchaseOrder = "purchase",
  GoodsReception = "goods-reception",
  Inventory = "warehouse/inventory",
}
