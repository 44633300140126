import { Client } from "@cumulus/event-bus";
import { User } from "@cumulus/event-bus";
import type { MenuItem } from "primevue/menuitem";

export function clientMenu(user: User, clients: Client[] | null, activeClientId: string): Array<MenuItem> {
  const menu = [] as Array<MenuItem>;
  let count = -1;

  clients?.forEach((client: Client) => {
    count = count++;
    const menuItem = {
      key: `0_3_${count}`,
      label: client.name,
      skiptranslate: true,
      icon: client.id === activeClientId ? "pi pi-fw pi-check" : "pi pi-fw",
      class: "c-client-item",
      command: () => {
        user.useClient(client.id);
        location.reload();
      },
    } as MenuItem;

    menu.push(menuItem);
  });

  return menu;
}
