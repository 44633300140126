{
  "common": {
    "error": "Error",
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Not found",
      "bad-request": "Bad request"
    },
    "countries": {
      "language": {
        "nb-NO": "Norwegian",
        "en-GB": "English"
      },
      "code": {
        "47": "Norway",
        "826": "The United Kingdom",
        "46": "Sweden",
        "49": "Germany",
        "61": "Australia",
        "55": "Brazil",
        "86": "China",
        "20": "Egypt",
        "33": "France",
        "91": "India",
        "81": "Japan",
        "34": "Spain",
        "1": "USA"
      },
      "iso": {
        "NO": "Norway",
        "GB": "The United Kingdom",
        "DK": "Denmark",
        "FI": "Finland",
        "SE": "Sweden",
        "DE": "Germany",
        "IT": "Italy",
        "FR": "France"
      }
    }
  },

  "header": {
    "menu": "Menu",
    "search": "Search",
    "alert-new-order": "New order",
    "alert-new-user": "New users",
    "alert-warnings": "Warnings",
    "profile-user": "User profile",
    "profile-language": "Language",
    "profile-companies": "Companies",
    "profile-clients": "Clients",
    "dark-mode": "Dark mode",
    "light-mode": "Light mode",
    "profile-logout": "Logout",
    "title": { 
      "order": "Order",
      "customer": "Customer",
      "discounts": "Discounts",
      "product": "Product",
      "supplier": "Supplier",
      "purchase": "Purchase order",
      "goods-reception": "Goods reception",
      "inventory": "Inventory",
      "inventory-count": "Inventory count",
      "order-ready-for-picking": "Ready for picking",
      "picking-lists": "Picking lists",
      "packing-notes": "Packing notes",
      "invoice": "Invoice",
      "credit-note": "Credit note",
      "transfer": "Transfer",
      "settings": "Settings",
      "company-info": "Company info",
      "clients": "Clients",
      "employees": "Employees",
      "printing": "Printing",
      "companies": "All companies",
      "health": "Health"
    }
  },

  "menu": {
    "dashboard": {
      "header": "Dashboard"
    },
    "sales": {
      "header": "Sales",
      "order": "Order",
      "order_char": "O",
      "customer": "Customer",
      "customer_char": "C",
      "discounts": "Discounts",
      "discounts_char": "D"
    },
    "product": {
      "header": "Product",
      "product": "Product",
      "product_char": "P",
      "supplier": "Supplier",
      "supplier_char": "S",
      "purchase": "Purchase",
      "purchase_char": "P"
    },
    "warehouse": {
      "header": "Warehouse",
      "goods_reception": "Goods reception",
      "goods_reception_char": "G",
      "inventory": "Inventory",
      "inventory_char": "I",
      "inventory_count": "Inventory count",
      "inventory_count_char": "C"
    },
    "goods_out":{
      "header": "Goods out",
      "order_ready_for_picking": "Ready for picking",
      "order_ready_for_picking_char": "R",
      "picking_lists": "Picking lists",
      "picking_lists_char": "P",
      "packing_notes": "Packing notes",
      "packing_notes_char": "N"
    },
    "finance": {
      "header": "Finance",
      "invoice": "Invoice",
      "invoice_char": "I",
      "credit_note": "Credit note",
      "credit_char": "C",
      "transfer": "Transfer",
      "transfer_char": "T",
      "settings": "Settings",
      "settings_char": "S"
    },
    "administration": {
      "header": "Administration",
      "company_info": "Company info",
      "company_info_char": "I",
      "clients": "Clients",
      "clients_char": "C",
      "employees": "Employees",
      "employees_char": "E",
      "settings": "Settings",
      "settings_char": "S",
      "printing": "Printing",
      "printing_char": "P"
    },
    "cumulus_tools":{
      "header":"Cumulus tools",
      "companies": "All companies",
      "companies_char": "A",
      "health": "Health",
      "health_char": "H"
    },
    "alerts":{
      "header": "Alerts",
      "new_order":"New order",
      "new_order_char": "",
      "new_users": "New users",
      "new_users_char": "",
      "warnings":"Warnings",
      "warnings_char": ""
    },
    "user":{
      "header": "User",
      "user_profile":"User profile",
      "user_profile_char": "",
      "language": "Language",
      "language_char": "",
      "language_norwegian":"Norwegian",
      "language_norwegian_char":"",
      "language_english":"English",
      "language_english_char":"",
      "logout":"Logout",
      "logout_char": ""
    }  
  },

  "admin-menu": {
    "header": "Home",
    "dashboard": "Dashboard",
    "administration": {
      "header": "Administration",
      "companies": "Companies",
      "identity": {
        "header": "Identity",
        "clients": "Clients",
        "users": "Users"
      }
    },
    "health": "Health"
  },

  "search": {
    "all": "All",
    "product": "Product | Products",
    "customer": "Customer | Customers",
    "order": "Order | Orders",
    "manufacturer": "Manufacturer | Manufacturers",
    "supplier": "Supplier | Suppliers",
    "purchase-order": "Purchase order | Purchase orders",
    "goods-reception": "Goods reception | Goods receptions",
    "inventory": "Inventory | Inventories",
    "customer-settings": "Customer settings",
    "show-all": "Show all",
    "no-search-results": "No search results for",
    "loading": "Searching..."
  }
}
