<template>
  <Popover
    ref="popoverRef"
    class="absolute overflow-y-auto max-h-[85vh] min-h-14 w-1/3 -ml-32 left-20 p-0 border rounded border-solid border-zinc-200 dark:border-stone-500 shadow-md"
    data-testid="global-search-result"
  >
    <ProgressBar mode="indeterminate" style="height: 0.2rem" v-show="loading" />
    <div>
      <div v-if="hasSearchResult(Pages.Product, props.productResult?.totalHits)" data-testid="product-result">
        <ProductSearchResult
          productType="product"
          :productResult="productResult"
          :supplierResult="supplierResult"
          @goToPage="(id: string | null, open: boolean) => goToPage(Pages.Product, id, open)"
        />
      </div>

      <div v-if="hasSearchResult(Pages.Inventory, props.inventoryResult?.totalHits)" data-testid="inventory-result">
        <ProductSearchResult
          productType="inventory"
          :productResult="inventoryResult"
          :supplierResult="supplierResult"
          @goToPage="(id: string | null, open: boolean) => goToPage(Pages.Inventory, id, open)"
        />
      </div>

      <div v-if="hasSearchResult(Pages.Customer, customerResult?.totalHits)" data-testid="customer-result">
        <CustomerSearchResult :customerResult="customerResult" @goToPage="goToPage" />
      </div>

      <div v-if="hasSearchResult(Pages.Order, orderResult?.totalHits)" data-testid="order-result">
        <OrderSearchResult :orderResult="orderResult" @goToPage="goToPage" />
      </div>

      <div v-if="hasSearchResult(Pages.Supplier, supplierResult?.totalHits)" data-testid="supplier-result">
        <SupplierSearchResult :supplierResult="supplierResult" @goToPage="goToPage" />
      </div>

      <div
        v-if="hasSearchResult(Pages.PurchaseOrder, purchaseOrderResult?.totalHits)"
        data-testid="purchase-order-result"
      >
        <PurchaseOrderSearchResult :purchaseOrderResult="purchaseOrderResult" @goToPage="goToPage" />
      </div>

      <div
        v-if="hasSearchResult(Pages.GoodsReception, goodsReceptionResult?.totalHits)"
        data-testid="goods-reception-result"
      >
        <GoodsReceptionSearchResult :goodsReceptionResult="goodsReceptionResult" @goToPage="goToPage" />
      </div>

      <div v-if="noSearchResult() && !loading">
        <div class="flex justify-center px-6 py-4">
          {{ t("search.no-search-results") }}
          <span class="italic ml-1"> '{{ props.searchQuery?.trim() }}'</span>
        </div>
      </div>

      <div v-if="loading">
        <div class="flex justify-center px-6 py-4">
          {{ t("search.loading") }}
        </div>
      </div>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { OrderSearchResponse } from "../models/order/OrderSearchResponse";
import { CustomerSearchResponse } from "../models/search/CustomerSearchResponse";
import { ProductSearchResponse } from "../models/product/ProductSearchResponse";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { PurchaseOrderSearchResponse } from "../models/purchase/PurchaseOrderSearchResponse";
import PurchaseOrderSearchResult from "./PurchaseOrderSearchResult.vue";
import SupplierSearchResult from "./SupplierSearchResult.vue";
import OrderSearchResult from "./OrderSearchResult.vue";
import CustomerSearchResult from "./CustomerSearchResult.vue";
import ProductSearchResult from "./ProductSearchResult.vue";
import GoodsReceptionSearchResult from "./GoodsReceptionSearchResult.vue";
import { Pages } from "../models/search/Pages";

const { t } = useI18n();

const props = defineProps<{
  searchQuery: string;
  searchContext: Pages;
  productResult: ProductSearchResponse | null;
  inventoryResult: ProductSearchResponse | null;
  customerResult: CustomerSearchResponse | null;
  orderResult: OrderSearchResponse | null;
  supplierResult: SupplierSearchResponse | null;
  purchaseOrderResult: PurchaseOrderSearchResponse | null;
  goodsReceptionResult: PurchaseOrderSearchResponse | null; //Goods reception search result is the same as purchase order search result
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "goToPage", page: Pages, id: string | null, openTab: boolean): void;
  (e: "update:searchContext", page: Pages): void;
}>();

const popoverRef = ref();

const hasSearchResult = (page: Pages, totalHits: number | undefined): boolean => {
  if (props.searchContext === page || props.searchContext === Pages.Default) {
    return totalHits && totalHits > 0 ? true : false;
  }
  return false;
};

const noSearchResult = () => {
  return (
    !hasSearchResult(Pages.Product, props.productResult?.totalHits) &&
    !hasSearchResult(Pages.Customer, props.customerResult?.totalHits) &&
    !hasSearchResult(Pages.Order, props.orderResult?.totalHits) &&
    !hasSearchResult(Pages.Supplier, props.supplierResult?.totalHits) &&
    !hasSearchResult(Pages.PurchaseOrder, props.purchaseOrderResult?.totalHits) &&
    !hasSearchResult(Pages.GoodsReception, props.goodsReceptionResult?.totalHits)
  );
};

const disableAutoFocusPriemVue = ref(false);
const show = (target: HTMLElement) => {
  if (disableAutoFocusPriemVue.value === true) {
    return;
  }
  popoverRef.value.show({ currentTarget: target });
};

const hide = (target: HTMLElement) => {
  disableAutoFocusPriemVue.value = true;
  popoverRef.value.hide({ currentTarget: target });
  disableAutoFocusPriemVue.value = false;
};

const goToPage = (page: Pages, id: string | null, openTab: boolean) => {
  emit("goToPage", page, id, openTab);
};

defineExpose({
  show,
  hide,
});
</script>
