import { Company } from "@cumulus/event-bus";
import { User } from "@cumulus/event-bus";
import { MenuItem } from "primevue/menuitem";

export function companyMenu(user: User, companies: Company[], activeCompanyId: string): Array<MenuItem> {
  const menu = [] as Array<MenuItem>;
  let count = -1;
  companies.forEach((company: Company) => {
    count = count++;
    const menuItem = {
      key: `0_2_${count}`,
      label: company.name,
      skiptranslate: true,
      icon: company.id === activeCompanyId ? "pi pi-fw pi-check" : "pi pi-fw",
      class: "c-company-item",
      command: () => {
        user.useCompany(company.id);
        location.reload();
      },
    } as MenuItem;

    menu.push(menuItem);
  });

  return menu;
}
