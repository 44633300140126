import { ref, computed } from "vue";

export function useLocalStorage() {
  const sidebarMini = ref(localStorage.getItem("sidebar-mini") === "true");

  const showSidebarMini = computed<boolean>({
    get: () => {
      return sidebarMini.value;
    },
    set: (active: boolean) => {
      sidebarMini.value = active;
      localStorage.setItem("sidebar-mini", active ? "true" : "false");
    },
  });

  return { showSidebarMini };
}
