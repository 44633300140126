import { i18n } from "@/locales/i18n";
import { createApp } from "vue";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "./assets/theme/default";
import vueDebounce from "vue-debounce";

import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import TieredMenu from "primevue/tieredmenu";
import InputText from "primevue/inputtext";
import Popover from "primevue/popover";
import ProgressBar from "primevue/progressbar";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import PanelMenu from "primevue/panelmenu";
import Select from "primevue/select";
import Badge from "primevue/badge";
import OverlayBadge from "primevue/overlaybadge";

// TODO: Build a new version of this package
// import AppInsights from "cumulus-vue-app-insight";

const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ToastService);
  app.use(i18n);

  app.component("Button", Button);
  app.component("PrimeButton", Button); //TODO: Remove - Is use by @CustomToast
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("TieredMenu", TieredMenu);
  app.component("Toast", Toast);
  app.component("InputText", InputText);
  app.component("Popover", Popover);
  app.component("ProgressBar", ProgressBar);
  app.component("PanelMenu", PanelMenu);
  app.component("Select", Select);
  app.component("Badge", Badge);
  app.component("OverlayBadge", OverlayBadge);

  app.directive("debounce", vueDebounce({ lock: true }));

  // TODO: Build a new version of this package
  // app.use(AppInsights, {
  //   baseName: "Portal",
  //   id: import.meta.env.VITE_APP_INSIGHTS_KEY,
  //   router,
  // });
}
