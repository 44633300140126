<template>
  <div></div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useAuth } from "@cumulus/event-bus";

const { logout } = useAuth();

onMounted(() => {
  window.location.href = "/";
  logout();
});
</script>
