<template>
  <div class="flex justify-between">
    <p class="font-semibold text-lg text-zinc-800 dark:text-stone-400">
      {{ t("search.order", 2) }} ({{ props.orderResult?.totalHits }})
    </p>
    <Button
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.Order, null, false)"
      data-testid="order-show-all"
    ></Button>
  </div>
  <div
    v-for="order in props.orderResult?.orders ?? []"
    :key="order.id"
    data-testid="order-result-row"
    class="c-search-description flex items-center hover:bg-primary-50 cursor-pointer"
  >
    <div class="min-w-12 max-w-12 p-2">
      <i class="pi pi-shopping-cart text-lime-500"></i>
    </div>

    <div @click="$emit('goToPage', Pages.Order, order.id, false)">
      <div class="truncate text-zinc-800 text-lg">{{ order.customer.customerName }}</div>
      <div class="truncate text-zinc-500 text-sm">{{ order.orderNumber }}</div>
    </div>

    <div class="c-link-folder w-full flex justify-end invisible">
      <div class="py-4 px-6 hover:bg-primary-200" @click="$emit('goToPage', Pages.Order, order.id, true)">
        <i class="pi pi-arrow-up-right"></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { OrderSearchResponse } from "../models/order/OrderSearchResponse";
import { Pages } from "../models/search/Pages";

const props = defineProps<{ orderResult: OrderSearchResponse | null }>();
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
<style lang="scss" scoped>
.c-search-description:hover .c-link-folder {
  visibility: visible;
}
</style>
