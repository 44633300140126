const resizeImage = (imageUrl: string, width: number, height: number, mode = "boxpad", output = ""): string => {
  const urlParts = imageUrl.replace("https://", "").split("/");

  //Check for domain and filename
  if (urlParts.length < 2) {
    return "";
  }
  const cdnUrl = import.meta.env.VITE_APP_IMAGE_CDN_URL + "/" + urlParts.slice(1).join("/");

  const args: string[] = [];
  if (width > 0) args.push(`w=${width}`);
  if (height > 0) args.push(`h=${height}`);
  if (mode.length > 0) args.push(`mode=${mode}`);
  if (output.length > 0) args.push(`output=${mode}`);

  if (args.length === 0) return cdnUrl;
  return cdnUrl + "?" + args.join("&");
};

export const useImageService = () => {
  return {
    resizeImage,
  };
};
