import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const layoutRoutes: RouteRecordRaw[] = [
  {
    name: "rootPage",
    path: "/:pathMatch(.*)*",
    component: (): Component => import("@/views/Default.vue"),
    meta: {
      authRequired: false,
    },
  },
  {
    name: "oauth2-redirect",
    path: "/oauth2-redirect",
    component: (): Component => import("../components/b2c/OAuth2Redirect.vue"),
  },
  {
    name: "Logout",
    path: "/logout",
    component: (): Component => import("../components/b2c/Logout.vue"),
  },
  {
    name: "Failed",
    path: "/failed",
    component: (): Component => import("../components/b2c/Failed.vue"),
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Routes",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "Guest layout",
          path: "/layout?unauthenticated",
          component: () => import("@/views/Default.vue"),
          meta: {
            authRequired: false,
          },
        },
        {
          name: "Auth layout",
          path: "/layout?authenticated",
          component: () => import("@/views/Default.vue"),
          meta: {
            authRequired: true,
          },
        },
      ]
    : [];
