<template>
  <div
    class="c-sidebar bg-gradient-to-b from-sidebar-50 to-sidebar-900 dark:from-stone-800 dark:to-stone-950"
    :class="{ 'c-sidebar-mobile': isMobile }"
  >
    <PanelMenu
      :model="items"
      :tabindex="500"
      :expandedKeys="expandedKeys"
      @panel-open="panelOpen"
      @panel-close="panelClose"
      pt:panel:class="border-0 pl-1 bg-transparent"
      pt:root:class="mt-2 ml-1 mr-1 mb-0 gap-0"
      pt:submenu:class="bold !hover:bg-sky-700"
    >
      <template #item="{ item }">
        <router-link v-if="(item as MenuItem).route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a
            class="mt-1 py-2 px-4 min-h-14 flex items-center cursor-pointer text-white dark:text-stone-400"
            :class="item.active ? 'router-link-active bg-zinc-50/25 rounded-lg' : ''"
            :href="href"
            @click="navigate"
          >
            <span v-if="item.icon" class="!text-2xl dark:text-stone-400 material-symbols-outlined">
              {{ item.icon }}</span
            >
            <span v-else class="ml-2 mr-2 dark:text-stone-400"> {{ t(item.label as string)[0] }} </span>

            <span class="ml-4 truncate dark:text-stone-400"> {{ t(item.label as string) }}</span>
          </a>
        </router-link>
        <a
          v-else
          class="mt-1 py-2 px-4 min-h-14 truncate flex items-center cursor-pointer text-white dark:text-stone-400"
          :class="item.active ? 'router-link-active ' : ''"
          :href="item.url"
          :target="item.target"
        >
          <span v-if="item.icon" class="!text-2xl dark:text-stone-400 material-symbols-outlined"> {{ item.icon }}</span>
          <span v-else class="dark:text-stone-400"> {{ t(item.label as string)[0] }} </span>

          <span class="ml-4 mr-2 truncate dark:text-stone-400"> {{ t(item.label as string) }}</span>
          <span v-if="item.items" class="c-menu-arrow pi pi-angle-right ml-auto"></span>
        </a>
      </template>
    </PanelMenu>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { PanelMenuExpandedKeys, PanelMenuPanelOpenEvent } from "primevue/panelmenu";
import { MenuItem } from "primevue/menuitem";

import { useRoute } from "vue-router";

defineProps<{ isMobile: boolean }>();

const expandedKeys = ref<PanelMenuExpandedKeys>([]);

const route = useRoute();
const { t } = useI18n();

const items = ref<Array<MenuItem>>([
  {
    key: "0",
    label: "menu.dashboard.header",
    icon: "dashboard",
    route: "/",
  },
  {
    key: "1",
    label: "menu.sales.header",
    icon: "shopping_cart",
    items: [
      {
        key: "/order/search/|/order/add|/order/edit",
        label: "menu.sales.order",
        route: "/order/search/",
        shortcut: "⌘+O",
      },
      {
        key: "/customer/search/|/customer/add|/customer/edit",
        label: "menu.sales.customer",
        route: "/customer/search/",
        shortcut: "⌘+C",
      },
      {
        key: "/price/discount/search|/price/discount/add|/price/discount/edit",
        label: "menu.sales.discounts",
        route: "/price/discount/search/",
        shortcut: "⌘+D",
      },
    ],
  },
  {
    key: "2",
    label: "menu.product.header",
    icon: "sell",
    items: [
      {
        key: "/product/search|/product/add|/product/edit",
        label: "menu.product.product",
        route: "/product/search/",
      },
      {
        key: "/supplier/search|/supplier/add|/supplier/edit",
        label: "menu.product.supplier",
        route: "/supplier/search/",
      },
      {
        key: "/purchase/search|/purchase/add|/purchase/edit|/purchase/received-goods",
        label: "menu.product.purchase",
        route: "/purchase/search/",
      },
    ],
  },
  {
    key: "3",
    label: "menu.warehouse.header",
    icon: "apartment",
    items: [
      {
        key: "/goods-reception/search/|/goods-reception/receive",
        label: "menu.warehouse.goods_reception",
        route: "/goods-reception/search/",
      },
      {
        key: "/warehouse/inventory/search",
        label: "menu.warehouse.inventory",
        route: "/warehouse/inventory/search",
      },
      {
        key: "/warehouse/inventory-count/search",
        label: "menu.warehouse.inventory_count",
        route: "/warehouse/inventory-count/search",
      },
    ],
  },
  {
    key: "4",
    label: "menu.goods_out.header",
    icon: "local_shipping",
    items: [
      {
        key: "/warehouse/goods-out/order-ready-for-picking",
        label: "menu.goods_out.order_ready_for_picking",
        route: "/warehouse/goods-out/order-ready-for-picking",
      },
      {
        key: "/warehouse/goods-out/open-picking-lists|/warehouse/goods-out/picking-list",
        label: "menu.goods_out.picking_lists",
        route: "/warehouse/goods-out/open-picking-lists",
      },
      {
        key: "/warehouse/goods-out/packing-notes",
        label: "menu.goods_out.packing_notes",
        route: "/warehouse/goods-out/packing-notes",
      },
    ],
  },
  {
    key: "5",
    label: "menu.finance.header",
    icon: "finance",
    items: [
      {
        key: "/finance/invoice",
        label: "menu.finance.invoice",
        route: "/finance/invoice",
      },
      {
        key: "/finance/credit-note",
        label: "menu.finance.credit_note",
        route: "/finance/credit-note",
      },
      {
        key: "/finance/transfer",
        label: "menu.finance.transfer",
        route: "/finance/transfer",
      },
      {
        key: "/finance/settings",
        label: "menu.finance.settings",
        route: "/finance/settings",
      },
    ],
  },
  {
    key: "6",
    label: "menu.administration.header",
    icon: "manufacturing",
    items: [
      {
        key: "/system-data/company",
        label: "menu.administration.company_info",
        route: "/system-data/company",
      },
      {
        key: "/system-data/clients|/system-data/client/add|/system-data/client/edit",
        label: "menu.administration.clients",
        route: "/system-data/clients",
      },
      {
        key: "/system-data/employees|/system-data/employee/add|/system-data/employee/edit",
        label: "menu.administration.employees",
        route: "/system-data/employees",
      },
      {
        key: "/system-data/settings|/warehouse/configurations|/freight/methods|/product/attributes|/system-data/imports",
        label: "menu.administration.settings",
        route: "/system-data/settings",
      },
      {
        key: "/system-data/printing",
        label: "menu.administration.printing",
        route: "/system-data/printing",
      },
    ],
  },
  {
    key: "7",
    label: "menu.cumulus_tools.header",
    icon: "settings_account_box",
    items: [
      {
        key: "/administration/company|/administration/company/add|/administration/company/edit",
        label: "menu.cumulus_tools.companies",
        route: "/administration/company",
      },
      {
        key: "/administration/healthstatus",
        label: "menu.cumulus_tools.health",
        route: "/administration/healthstatus",
      },
    ],
  },
  {
    key: "8",
    label: "menu.alerts.header",
    icon: "notifications",
    items: [
      {
        key: "8_1",
        label: "menu.alerts.new_order",
        command: () => {
          alert("Under construction");
        },
      },
      {
        key: "8_2",
        label: "menu.alerts.new_users",
        command: () => {
          alert("Under construction");
        },
      },
      {
        key: "8_3",
        label: "menu.alerts.warnings",
        command: () => {
          alert("Under construction");
        },
      },
    ],
  },
  {
    key: "9",
    label: "menu.user.header",
    icon: "person",
    items: [
      {
        key: "9_1",
        label: "menu.user.user_profile",
        icon: "pi pi-fw pi-user-edit",
        command: () => {
          alert("Under construction");
        },
      },
      {
        key: "9_2",
        label: "menu.user.language",
        icon: "pi pi-fw pi-flag",
        command: () => {
          alert("Under construction");
        },
      },
      {
        key: "9_3",
        label: "menu.user.logout",
        icon: "pi pi-fw pi-power-off",
        command: () => {
          alert("Under construction");
        },
      },
    ],
  },
]);

onMounted(() => {
  if (route.path === "/") {
    expandedKeys.value = { "0": true };
    items.value[0].active = true;
    return;
  }

  items.value.forEach((level1) => {
    if (level1.items !== undefined) {
      level1.items?.forEach((level2) => {
        if (level2.key !== undefined) {
          const keys = level2.key.split("|");
          keys.forEach((key) => {
            if (route.path.startsWith(key)) {
              if (level1.key) {
                expandedKeys.value[level1.key] = true;
              }
              if (level2.key) {
                expandedKeys.value[level2.key] = true;
              }
              level1.active = true;
              level2.active = true;
              return;
            }
          });
        }
      });
    }
  });
});

const panelOpen = (event: PanelMenuPanelOpenEvent) => {
  const value = [] as PanelMenuExpandedKeys;

  const levels = event.item.key.split("_");
  for (let i = 0; i < levels.length; i++) {
    const key = levels.slice(0, i + 1).join("_");
    value.push(key);
    value[key] = true;
  }
  expandedKeys.value = value;
};

const panelClose = (event: PanelMenuPanelOpenEvent) => {
  const levels = event.item.key.split("_");
  for (let i = 0; i < levels.length; i++) {
    const key = levels.slice(0, i + 1).join("_");

    if (expandedKeys.value[key] !== undefined) {
      delete expandedKeys.value[key];
    }
  }
};
</script>

<style lang="scss" scoped>
.c-sidebar {
  width: 240px;
  display: block;
  position: fixed;
  top: 71px;
  bottom: 0;
  left: 0;
  z-index: 4;
  min-height: 100vh;
  overflow: auto;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

  @media (max-width: 687px) {
    top: 0;
    bottom: 10vh;
  }
  @media (min-width: 687px) and (max-width: 992px) {
    top: 0;
    bottom: 8vh;
  }
}

.c-sidebar-mini .c-sidebar {
  width: 65px;
  @media (min-width: 992px) {
    display: block;
    font-weight: 200;
    z-index: 3;
  }

  &:hover {
    width: 260px;

    .c-button-min {
      display: block;
    }

    :deep(.p-panelmenu) .p-menuitem-text,
    :deep(.p-panelmenu) .p-submenu-icon {
      width: inherit;
    }
  }
}

.c-sidebar.c-sidebar-mobile,
.c-sidebar-mini .c-sidebar.c-sidebar-mobile {
  width: 0;
}

.c-sidebar-show .c-sidebar.c-sidebar-mobile,
.c-sidebar-show .c-sidebar.c-sidebar-mini.c-sidebar-mobile {
  width: 280px;
  transform: translateZ(0);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.c-sidebar :deep(.p-panelmenu) .p-panelmenu-item:not(.p-disabled) > .p-panelmenu-item-content:hover {
  background: #ffffff25;
}
.c-sidebar :deep(.p-panelmenu) .p-panelmenu-header:not(.p-disabled) .p-panelmenu-header-content:hover {
  background: #ffffff25;
}

.c-sidebar .c-menu-arrow {
  transition: 0.2s ease-in-out;
}
.c-sidebar .p-panelmenu-header-active .c-menu-arrow {
  transform: rotate(90deg);
}

//Fix for PrimeVue bug in: pt:rootlist:class="pl-10"
.c-sidebar :deep(.p-panelmenu) .p-panelmenu-root-list {
  padding: 0;
}

@media (min-width: 992px) {
  .c-sidebar :deep(.p-panelmenu) .p-panelmenu-header {
    &[aria-label="menu.alerts.header"],
    &[aria-label="menu.user.header"] {
      display: none;
    }
  }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
</style>
