export function useBodyCss() {
  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += " " + className;
    }
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) {
      element.classList.remove(className);
    }
  };

  const addSidebarMiniCss = () => {
    addClass(document.body, "c-sidebar-mini");
  };

  const removeSidebarMiniCss = () => {
    removeClass(document.body, "c-sidebar-mini");
  };

  const addShowMobileMenuCss = () => {
    addClass(document.body, "c-sidebar-show");
  };

  const removeShowMobileMenuCss = () => {
    removeClass(document.body, "c-sidebar-show");
  };

  return {
    addSidebarMiniCss,
    removeSidebarMiniCss,
    addShowMobileMenuCss,
    removeShowMobileMenuCss,
  };
}
