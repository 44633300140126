import { httpClient, AuthHeaders } from "@cumulus/http";
import { SearchRequest } from "../../models/search/SearchRequest";
import { ProductSearchResponse } from "../../models/product/ProductSearchResponse";
import { SupplierSearchResponse } from "../../models/supplier/SupplierSearchResponse";
import { CustomerSearchResponse } from "../../models/search/CustomerSearchResponse";
import { OrderSearchResponse } from "../../models/order/OrderSearchResponse";
import { PurchaseOrderSearchResponse } from "../../models/purchase/PurchaseOrderSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != undefined && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async searchProduct(authHeaders: AuthHeaders, request: SearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }

  public async supplierSearch(authHeaders: AuthHeaders, request: SearchRequest): Promise<SupplierSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/suppliers/search", request)
      .then(({ data }) => data);
  }

  public async customerSearch(authHeaders: AuthHeaders, request: SearchRequest): Promise<CustomerSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/customers/search", request)
      .then(({ data }) => data);
  }

  public async orderSearch(authHeaders: AuthHeaders, request: SearchRequest): Promise<OrderSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/orders/search", request)
      .then(({ data }) => data);
  }

  public async purchaseOrderSearch(
    authHeaders: AuthHeaders,
    request: SearchRequest
  ): Promise<PurchaseOrderSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/purchase-orders/search", request)
      .then(({ data }) => data);
  }
}
const searchApi = new SearchApi();
export { searchApi };
