<template>
  <div>
    <Auth v-if="isLoggedIn === true" />
    <Guest v-if="isLoggedIn === false" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuth, InteractionStatus } from "@cumulus/event-bus";
import Guest from "./guest/Guest.vue";
import Auth from "./auth/Auth.vue";

const route = useRoute();

const { login, logout, getUser, isAuthenticated, inProgress, accounts } = useAuth();

const isLoggedIn = ref<boolean | null>(null);

const authenticate = async () => {
  if (inProgress.value === InteractionStatus.None) {
    const user = await getUser();
    if (accounts.value.length > 0) {
      if (route.query?.unauthenticated !== undefined) {
        await logout();
        return;
      }

      document.body.className = user.getBodyCssClass(isAuthenticated.value);
      isLoggedIn.value = true;
      return;
    }

    if (route.query?.authenticated !== undefined) {
      await login();
      return;
    }

    if (route.name != undefined && route.name === "oauth2-redirect") {
      return;
    }

    document.body.className = user.getBodyCssClass(isAuthenticated.value);
    isLoggedIn.value = false;
  }
};

watch(inProgress, authenticate);
watch(accounts, authenticate);

onMounted(authenticate);
</script>
