<template>
  <div class="c-navbar-guest flex justify-center">
    <Header />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import Header from "./components/Header.vue";

const setBodyClass = () => {
  removeClass(document.body, "c-auth");
  addClass(document.body, "c-guest");
};

const addClass = (element: HTMLElement, className: string) => {
  if (element.classList) {
    element.classList.add(className);
  } else {
    element.className += " " + className;
  }
};

const removeClass = (element: HTMLElement, className: string) => {
  if (element.classList) {
    element.classList.remove(className);
  }
};

onMounted(setBodyClass);
</script>

<style lang="scss">
.c-navbar-guest {
  background-color: var(--surface-a);
  position: fixed;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0 auto;
}

.c-guest .c-layout-main-container {
  padding-top: 3.5rem;
}
</style>
