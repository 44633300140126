<template>
  <div class="flex justify-between">
    <p class="font-semibold text-lg text-zinc-800 dark:text-stone-400">
      {{ t("search.goods-reception", 2) }} ({{ props.goodsReceptionResult?.totalHits ?? 0 }})
    </p>
    <Button
      :label="t('search.show-all')"
      class="p-button-link"
      @click="$emit('goToPage', Pages.GoodsReception, null, false)"
      data-testid="goods-reception-show-all"
    ></Button>
  </div>
  <div
    v-for="goodsReception in props.goodsReceptionResult?.purchaseOrders ?? []"
    :key="goodsReception.id"
    data-testid="goods-reception-result-row"
    class="c-search-description flex items-center hover:bg-primary-50 cursor-pointer"
  >
    <div class="min-w-12 max-w-12 p-2">
      <i class="pi pi-sign-in text-sky-500"></i>
    </div>

    <div @click="$emit('goToPage', Pages.GoodsReception, goodsReception.id, false)">
      <span class="truncate text-zinc-800 dark:text-stone-500 text-lg">{{ goodsReception.supplier.name }}</span>
      <span class="truncate text-zinc-500 dark:text-stone-600 text-sm">{{ goodsReception.purchaseOrderNumber }}</span>
    </div>

    <div class="c-link-folder w-full flex justify-end invisible">
      <div
        class="py-4 px-6 hover:bg-primary-200"
        @click="$emit('goToPage', Pages.GoodsReception, goodsReception.id, true)"
      >
        <i class="pi pi-arrow-up-right"></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Pages } from "../models/search/Pages";
import { PurchaseOrderSearchResponse } from "../models/purchase/PurchaseOrderSearchResponse";

const props = defineProps<{ goodsReceptionResult: PurchaseOrderSearchResponse | null }>(); //Goods reception search result is the same as purchase order search result
defineEmits<{ (event: "goToPage", page: Pages, id: string | null, openTab: boolean): void }>();

const { t } = useI18n();
</script>
<style lang="scss" scoped>
.c-search-description:hover .c-link-folder {
  visibility: visible;
}
</style>
