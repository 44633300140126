import { MenuItem } from "primevue/menuitem";
import router from "@/router/router";

export function alertMenu(): Array<MenuItem> {
  return [
    {
      label: "header.alert-new-order",
      icon: "pi pi-fw pi-shopping-cart",
      command: () => {
        alert("Under construction");
      },
    },
    {
      label: "header.alert-new-user",
      icon: "pi pi-fw pi-users",
      command: () => {
        alert("Under construction");
      },
    },
    {
      label: "header.alert-warnings",
      icon: "pi pi-fw pi-info-circle",
      command: () => {
        alert("Under construction");
        router.push(`/`);
      },
    },
  ] as Array<MenuItem>;
}
