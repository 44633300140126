<template>
  <div
    class="c-global-search p-inputgroup flex justify-center"
    :class="{ active: isFocusSearchInput || isFocusSearchDropdown }"
  >
    <Select
      v-model="selectedContext"
      :options="contextOptions"
      optionLabel="label"
      optionValue="value"
      class="c-global-search-dropdown rounded-l rounded-r-none border-solid border-[#F0F6FA] bg-[#F0F6FA] dark:border-stone-500 dark:bg-stone-600 mr-1"
      @change="changedSearchContext"
      @focus="isFocusSearchDropdown = true"
      @blur="
        timeout(300).then(() => {
          isFocusSearchDropdown = false;
        })
      "
    />
    <InputText
      class="c-global-search-input leading-4 bg-[#F0F6FA] text:slate-950 rounded border-none focus:border focus:border-solid hover:border hover:border-solid border-[#F0F6FA] hover:bg-[#F0F6FA] hover:border-[#F0F6FA] active:bg-[#F0F6FA] active:border-[#F0F6FA] focus:bg-[#F0F6FA] focus:border-[#F0F6FA] max-w-md dark:bg-stone-700 dark:text:stone-200 dark:border-stone-950 dark:hover:bg-stone-600 dark:hover:border-stone-500 dark:active:bg-stone-600 dark:active:border-stone-500 dark:focus:bg-stone-600 dark:focus:border-stone-500 min-w-56 lg:min-w-96"
      ref="refSearchInput"
      data-testid="search-input"
      type="text"
      v-model="searchQuery"
      v-debounce:400="instanceSearch"
      :placeholder="t('header.search')"
      @keypress.enter="showSearchResult"
      @keydown.tab.prevent="showSearchResult"
      @focus="isFocusSearchInput = true"
      @blur="
        timeout(300).then(() => {
          isFocusSearchInput = false;
        })
      "
      id="searchInput"
    />
    <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
  </div>

  <SearchResult
    ref="searchResultPanel"
    :searchQuery="searchQuery"
    :productResult="productResult"
    :inventoryResult="inventoryResult"
    :supplierResult="supplierResult"
    :customerResult="customerResult"
    :orderResult="orderResult"
    :purchaseOrderResult="purchaseOrderResult"
    :goodsReceptionResult="goodsReceptionResult"
    :loading="loadingSearch"
    v-model:searchContext="selectedContext"
    @goToSearchResult="goToSearchResult"
    @goToPage="goToPage"
  ></SearchResult>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSearch } from "../api/search/SearchService";
import SearchResult from "./SearchResult.vue";

import { OrderSearchResponse } from "../models/order/OrderSearchResponse";
import { SupplierSearchResponse } from "../models/supplier/SupplierSearchResponse";
import { CustomerSearchResponse } from "../models/search/CustomerSearchResponse";
import { ProductSearchResponse } from "../models/product/ProductSearchResponse";
import { PurchaseOrderSearchResponse } from "../models/purchase/PurchaseOrderSearchResponse";
import { Pages } from "../models/search/Pages";
import { useRouter } from "vue-router";

const { t } = useI18n();
const loadingSearch = ref(false);

const contextOptions = ref([
  { label: t("search.all"), value: Pages.Default },
  { label: t("search.product"), value: Pages.Product },
  { label: t("search.customer"), value: Pages.Customer },
  { label: t("search.order"), value: Pages.Order },
  { label: t("search.supplier"), value: Pages.Supplier },
  { label: t("search.purchase-order"), value: Pages.PurchaseOrder },
  { label: t("search.goods-reception"), value: Pages.GoodsReception },
  { label: t("search.inventory"), value: Pages.Inventory },
]);

const productResult = ref<ProductSearchResponse | null>(null);
const customerResult = ref<CustomerSearchResponse | null>(null);
const orderResult = ref<OrderSearchResponse | null>(null);
const supplierResult = ref<SupplierSearchResponse | null>(null);
const purchaseOrderResult = ref<PurchaseOrderSearchResponse | null>(null);
const goodsReceptionResult = ref<PurchaseOrderSearchResponse | null>(null); //Goods reception search result is the same as purchase order search result
const inventoryResult = ref<ProductSearchResponse | null>(null); //Goods reception search result is the same as purchase order search result

const refSearchInput = ref();
const searchResultPanel = ref();
const searchQuery = ref("");
const selectedContext = ref<Pages>(Pages.Default);
const router = useRouter();

const {
  searchProduct,
  searchCustomer,
  searchOrder,
  searchSupplier,
  searchPurchaseOrder,
  goToSearchResult,
  searchKeyFromUrl,
  currentSearchContext,
  isSearchResultPage,
  focusSearchInput,
} = useSearch();

defineProps<{
  isMobile: boolean;
}>();

onMounted(() => {
  selectedContext.value = currentSearchContext();
  searchQuery.value = searchKeyFromUrl();
  if (selectedContext.value !== Pages.Default && isSearchResultPage(selectedContext.value)) {
    if (focusSearchInput()) {
      refSearchInput.value.$el.focus();
    }
  }
});

const isFocusSearchInput = ref(false);
const isFocusSearchDropdown = ref(false);

const changedSearchContext = () => {
  // Wait for dropdown to close
  //TODO: Write code for using MutationObserver API to check when dropdown is closed and searchContext is changed. Then remove this timeout.
  timeout(100).then(() => {
    refSearchInput.value.$el.focus();
    instanceSearch();
  });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const instanceSearch = async () => {
  searchResultPanel.value?.show(refSearchInput.value.$el);
  loadingSearch.value = true;

  try {
    switch (selectedContext.value) {
      case Pages.Default: {
        const [product, customer, order, supplier, purchaseOrder] = await Promise.all([
          searchProduct(searchQuery.value),
          searchCustomer(searchQuery.value),
          searchOrder(searchQuery.value),
          searchSupplier(searchQuery.value),
          searchPurchaseOrder(searchQuery.value),
        ]);
        productResult.value = product;
        customerResult.value = customer;
        orderResult.value = order;
        supplierResult.value = supplier;
        purchaseOrderResult.value = purchaseOrder;
        goodsReceptionResult.value = purchaseOrder; // Goods reception search result is the same as purchase order search result
        inventoryResult.value = product; // Inventory search result is the same as product search result
        break;
      }

      case "product":
        productResult.value = await searchProduct(searchQuery.value);
        break;
      case "customer":
        customerResult.value = await searchCustomer(searchQuery.value);
        break;
      case "order":
        orderResult.value = await searchOrder(searchQuery.value);
        break;
      case "supplier":
        supplierResult.value = await searchSupplier(searchQuery.value);
        break;
      case "purchase":
        purchaseOrderResult.value = await searchPurchaseOrder(searchQuery.value);
        break;
      case "goods-reception":
        // Goods reception search result is same as purchase order search result!
        goodsReceptionResult.value = await searchPurchaseOrder(searchQuery.value);
        break;
      case "warehouse/inventory":
        // Inventory search result is same as product search result!
        inventoryResult.value = await searchProduct(searchQuery.value);
    }
  } finally {
    loadingSearch.value = false;
  }
};

const showSearchResult = () => {
  if (selectedContext.value === Pages.Default) {
    searchResultPanel.value.show(refSearchInput.value.$el);
    return;
  }

  const page = currentSearchContext();

  if (selectedContext.value === page) {
    searchResultPanel.value.hide();
    goToSearchResult(selectedContext.value, searchQuery.value);

    const searchList = window.document.querySelector(".c-search-result-row:first-child") as HTMLElement;
    if (searchList !== null) searchList.focus();
    return;
  }
  searchResultPanel.value.hide();
  goToSearchResult(selectedContext.value, searchQuery.value);
};

const goToPage = (page: Pages, id: string | null, openTab: boolean) => {
  if (id === null) {
    goToSearchResult(page, searchQuery.value);
    return;
  }

  if (openTab) {
    if (page === Pages.Inventory) {
      window.open(`/${page}/search/${id}?modal=view`, "_blank");
    } else if (page === Pages.Product) {
      window.open(`/${page}/edit/${id}`, "_blank");
    } else {
      window.open(`/${page}/view/${id}`, "_blank");
    }
    return;
  }

  if (page === Pages.Inventory) {
    goToSearchResult(page, searchQuery.value, id);
  } else if (page === Pages.GoodsReception) {
    router.push(`/${page}/receive/${id}`);
  } else {
    router.push(`/${page}/edit/${id}`);
  }
};
</script>

<style lang="scss" scoped>
.c-global-search {
  border-radius: 4px;
  transition: width 0.5s ease;

  @media (max-width: 992px) {
    margin-right: 1rem;
  }
}

.c-global-search-dropdown .p-dropdown-trigger {
  margin-top: 0.6rem;
  margin-right: 0.2rem;
}

.c-global-search-dropdown.p-select {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.c-global-search.p-inputgroup .c-global-search-dropdown.p-inputwrapper {
  display: none;
}

.c-global-search.p-inputgroup.active .c-global-search-dropdown.p-inputwrapper {
  display: flex;
  width: 10rem;

  @media (max-width: 992px) {
    width: 7rem;
  }
}

.c-global-search .p-inputgroup-addon.p-button {
  border-radius: 0 3px 3px 0;
  &:last-child {
    border-right: none;
  }
}

.c-global-search.active .c-global-search-input.p-inputtext {
  border-radius: 0px 4px 4px 0px;
  min-width: 27rem;

  @media (max-width: 992px) {
    min-width: 14rem;
  }
}

.c-global-search .p-inputtext:enabled:focus {
  box-shadow: none;
}

@media (max-width: 992px) {
  .c-global-search.p-inputgroup .c-global-search-dropdown.p-inputwrapper {
    width: 2.5rem;
  }
  :deep(.p-dropdown-label) {
    display: none;
  }
  :deep(.p-dropdown .p-dropdown-trigger) {
    margin: 0.5rem;
  }
}
</style>
